<template>
  <div :class="activeFont">
    <Customizer @onLogo="changeLogo" @toggle="sidebarMini" @animationChange="routerAnimationChange" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SmallSidebar v-if="$route.name === 'dashboard.home-5'" :logo="SmallSidebarLogo" @toggle="sidebarMini" />
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText" @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar :homeURL="{ name: 'authSignUp.dashboard' }" :sidebarGroupTitle="sidebarGroupTitle" @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item d-flex" v-nav-toggle>
              <lang-button/>
            </li>
            <li class="nav-item" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect" v-if="$store.state.Auth.activeRoleId !== 1">
                <i class="ri-notification-line"></i>
                <span class="dots"></span>
                <small class="notification_count" v-if="totalNotification">{{ totalNotification }}</small>
              </a>
              <notification :component_id="3"/>
            </li>
          </ul>
        </template>
        <template slot="right">
          <SiteSettings :authServiceBaseUrl="authServiceBaseUrl" :authUser="authUser"/>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page" v-bind:class="{ ' ministry-user': isMinistryUser }">
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </div>
    </div>
    <PushNotification :component_id="3" />
    <LayoutFooter />
  </div>
</template>
<script>
import { authServiceBaseUrl } from '@/config/api_config'
import { core } from '../config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import Sidebar from '../components/core/sidebars/Sidebar'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import profile from '../assets/images/user/user-1.jpeg'
import Logo from '../assets/images/logo.png'
import SiteSettings from './Components/SitePreferenceSetting'
import Notification from './Components/Notification'
import Customizer from './Components/Customizer'
import SmallSidebar from '../components/core/sidebars/SmallSidebar'
import LayoutFooter from './Components/LayoutFooter'
import DropdownMixin from '@/mixins/dropdown'
import AgriReserchDropdownMixin from '@/mixins/dropdown-agri-research'
import stateVars from '@/Utils/state-vars/agri-research-service'
import LangButton from './Components/LangButton'
import PushNotification from './Components/PushNotification'

export default {
  name: 'VerticleLayout',
  mixins: [DropdownMixin, AgriReserchDropdownMixin],
  components: {
    LayoutFooter,
    SiteSettings,
    Notification,
    Customizer,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    PushNotification,
    LangButton
  },
  created () {
    this.mixinSetOrganizationProfileList()
    if (window.performance) {
      this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
      // this.langChange(this.selectedLang)
      this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
      // this.langChange(this.selectedLang)
    }
  },
  computed: {
    totalNotification () {
      return this.$store.state.totalNotification
    },
    ...mapGetters({
      authUser: 'Auth/authUser',
      verticalMenu: 'Auth/getActiveMenus'
    }),
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  // sidebarTicket
  data () {
    return {
      langLabel: this.$i18n.locale === 'bn' ? this.$t('globalTrans.en') : this.$t('globalTrans.bn'),
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      userProfile: profile,
      onlyLogo: true,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: Logo,
      rtl: false,
      SmallSidebarLogo: Logo,
      mixinComponentId: 3,
      authServiceBaseUrl: authServiceBaseUrl
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeCommonDropdownAgriResearch({ value: newVal })
      }
    }
  },
  methods: {
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    logout () {
      this.$store.dispatch('Auth/updateAuthUser', null)
      this.$store.commit('mutateCommonProperties', {
        hasDropdownLoaded: false
      })
      this.$store.commit('AgriResearch/mutateCommonObj', {
        hasDropdownLoaded: false
      })
      this.$router.push('/auth/login')
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    ...mapActions({
      localizeCommonDropdownAgriResearch: 'AgriResearch/changeCommonObjDropdown'
    })
  },
  beforeDestroy () {
    this.$store.dispatch('clearStoreBasedOnLayout', { destination: 'AgriResearch', stateVars: stateVars })
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>

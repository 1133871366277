import Custom from '@/modules/agri-research/store/custom'

const stateVars = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    organizationType: Custom.organizationType,
    proposalStatusList: Custom.proposalStatusList,
    projectStatusList: Custom.projectStatusList,
    seedTypeNameList: [],
    seedNameList: [],
    seedVarietyList: [],
    seedClassNameList: [],
    publicationTypeList: [],
    publicationTitleList: [],
    qualityUnitList: [],
    publicationContentAreaList: [],
    productionSeasonList: [],
    seedPacketList: [],
    serviceList: [],
    projectList: [],
    subProjectList: [],
    sectorList: [],
    subSectorList: [],
    thematicAreaList: [],
    researchDivisionList: [],
    agencyList: [],
    budgetHeadList: [],
    labInfoList: [],
    componentOrgList: [],
    feePolicy: [],
    researchTypeList: Custom.researchTypeList,
    circularList: []
  }
}
export default stateVars

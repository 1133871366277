import RestApi, { agriResearchServiceBaseUrl } from '../config/api_config'
export default {
    data () {
        return {}
    },
  computed: {
    hasDropdownLoadedResearch () {
      return this.$store.state.AgriResearch.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedResearch: function (newValue) {
      if (!newValue) {
        this.loadDropdownResearch()
      }
    }
  },
  created () {
    const hasDropdownLoadedAgriResearchService = this.$store.state.AgriResearch.hasDropdownLoaded
    if (!hasDropdownLoadedAgriResearchService || window.performance) {
    }
    this.loadDropdownResearch()
  },
  methods: {
    loadDropdownResearch () {
      RestApi.getData(agriResearchServiceBaseUrl, '/common-dropdowns', null).then(response => {
        if (response.success === true) {
          this.$store.dispatch('AgriResearch/mutateCommonObj', {
            hasDropdownLoaded: true,
            seedTypeNameList: response.data.seedTypeNameList,
            seedNameList: response.data.seedNameList,
            seedClassNameList: response.data.seedClassNameList,
            qualityUnitList: response.data.qualityUnitList,
            productionSeasonList: response.data.productionSeasonList,
            seedVarietyList: response.data.seedVarietyList,
            serviceList: response.data.serviceList,
            projectList: response.data.projectList,
            subProjectList: response.data.subProjectList,
            sectorList: response.data.sectorList,
            researchDivisionList: response.data.researchDivisionList,
            seedPacketList: response.data.seedPacketList,
            publicationTypeList: response.data.publicationTypeList,
            publicationTitleList: response.data.publicationTitleList,
            publicationFeeList: response.data.publicationFeeList,
            thematicAreaList: response.data.thematicAreaList,
            subSectorList: response.data.subSectorList,
            publicationContentAreaList: response.data.publicationContentAreaList,
            agencyList: response.data.agencyList,
            budgetHeadList: response.data.budgetHeadList,
            budgetTypeList: response.data.budgetTypeList,
            labInfoList: response.data.labInfoList,
            circularList: response.data.circularList,
            feePolicy: response.data.feePolicy,
            componentOrgList: response.data.componentOrgList,
            divisionalResearchList: response.data.divisionalResearchList,
            roleInformationList: response.data.roleInformationList,
            monitoringTeamList: response.data.monitoringTeamList,
            schemeList: response.data.schemeList,
            seedProcessingCentersList: response.data.seedProcessingCentersList,
            divisionalCircularList: response.data.divisionalCircularList
          })
          this.$store.dispatch('AgriResearch/changeCommonObjDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
